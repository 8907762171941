import { GnosisDelegation } from '@spruceid/ssx-gnosis-extension';
import { KeplerOptions } from '@spruceid/kepler-sdk-wrapper';
import {
  ProviderType, SSXConnected, SSXCoreConfig, SSXInit, SSXSession,
} from './core';
import { Kepler } from './kepler';

const SSX_DEFAULT_CONFIG: SSXConfig = {
  provider: {
    type: ProviderType.Metamask,
  },
};

/** SSX configuration. */
export type SSXConfig = SSXCoreConfig & {
  storage?: StorageModule,
  /** Enables DelegationHistory contracts lookup for delegations. */
  delegationLookup?: boolean,
};

/** Selection and configuration of the storage module. */
export type StorageModule = KeplerConfig;

/** Supported storage types. */
export enum StorageType {
  Kepler = 'kepler'
}

/** Kepler storage module configuration. */
export type KeplerConfig = { type: StorageType.Kepler } & KeplerOptions;

/** A Storage module. */
export interface Storage {
  /** Retrieve a value from storage. */
  get(key: string): Promise<any>,
  /** Insert a key-value pair into storage. */
  put(key: string, value: any): Promise<any>,
  /** List the stored values by key, optionally filtered by prefix. */
  list(prefix?: string): Promise<string[]>,
  /** Delete a value from storage. */
  delete(key: string): Promise<any>,
}

/** SSX: Self-sovereign anything.
 *
 * A toolbox for user-controlled identity, credentials, storage and more.
 */
export class SSX {
  /** SSXSession builder. */
  private init: SSXInit;

  /** The session representation (once signed in). */
  private session?: SSXSession;

  /** User-controlled storage for your application to use. */
  public storage?: Storage;

  /** User-controlled storage under a shared namespace that any SSX app could use. */
  public sharedStorage?: Storage;

  /** Current connection of SSX */
  public connection?: SSXConnected;

  constructor(private config: SSXConfig = SSX_DEFAULT_CONFIG) {
    this.init = new SSXInit({ ...this.config });
    if (this.config.storage?.type === StorageType.Kepler) {
      if (!this.config.storage?.hosts || this.config.storage.hosts.length < 1) {
        throw new Error("'storage.hosts' is required configuration if 'storage.type' === 'kepler'");
      }
      const storage = new Kepler(this.config.storage, `appslot/${window.location.hostname}`);
      this.init.extend(storage);
      this.storage = storage;

      const sharedStorage = new Kepler(this.config.storage, 'shared');
      this.init.extend(sharedStorage);
      this.sharedStorage = sharedStorage;
    }

    if (this.config.delegationLookup) {
      const gnosis = new GnosisDelegation();
      this.init.extend(gnosis);
    }
  }

  /** Request the user to sign in, and start the session. */
  async signIn() {
    try {
      this.connection = await this.init.connect();
    } catch (err) {
      // Something went wrong when connecting or creating Session (wasm)
      console.error(err);
      throw err;
    }

    try {
      this.session = await this.connection.signIn();
    } catch (err) {
      // Request to /ssx-login went wrong
      console.error(err);
      throw err;
    }
  }

  async signOut() {
    try {
      await this.connection.signOut();
      this.session = null;
      this.connection = null;
    } catch (err) {
      // request to /ssx-logout went wrong
      console.error(err);
      throw err;
    }
  }

  /** Get the address that is connected and signed in. */
  address: () => string | undefined = () => this.session?.address;

  /** Get the chainId that the address is connected and signed in on. */
  chainId: () => number | undefined = () => this.session?.chainId;
}
