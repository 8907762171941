import WalletConnectProvider from "@walletconnect/web3-provider";
import { ProviderType } from "@spruceid/ssx";

const ssxConfig = { 
	provider: {
		type: ProviderType.Web3Modal,
		config: {
			providerOptions: {
				walletconnect: {
					package: WalletConnectProvider,
					options: {
						infuraId: process.env.REACT_APP_SSX_INFURA_ID,
					},
				},
			},
		}
	},
	server: process.env.REACT_APP_SSX_METRICS_SERVER,
	delegationLookup: !!(process.env.REACT_APP_SSX_DELEGATION_LOOKUP === "true"),
};

export default ssxConfig; 