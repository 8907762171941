import merge from 'lodash.merge';
import {
  KeplerOptions, OrbitConnection, activateSession, hostOrbit, WalletProvider,
} from '@spruceid/kepler-sdk-wrapper';
import { initialized, kepler } from '@spruceid/ssx-wasm';
import { SiweMessage } from '@spruceid/siwe';
import { SSXConnected, SSXSession } from './core';
import { ConfigOverrides, SSXExtension } from './extension';
import { Storage } from './ssx';

/** Wrapper for Kepler to work as an SSXExtension and a Storage module. */
export class Kepler implements SSXExtension, Storage {
  private config: KeplerOptions;

  /** The users wallet. */
  private wallet?: WalletProvider;

  /** The users orbitId. */
  private orbitId?: string;

  /** The orbit prefix to query against. */
  private prefix: string;

  /** The connection to the orbit. */
  orbit?: OrbitConnection;

  /** The domain to display in the SIWE message. */
  domain?: string;

  /** Capgrok namespace. */
  namespace: string = 'kepler';

  constructor(config: KeplerOptions, prefix: string) {
    this.config = config;
    this.prefix = prefix;
  }

  private getOrbit(): OrbitConnection {
    if (!this.orbit) {
      throw new Error('sign in before using storage');
    }
    return this.orbit;
  }

  async get(key: string): Promise<any> {
    const { ok, statusText, data } = await this.getOrbit().get(`${this.prefix}/${key}`);
    if (!ok) {
      throw statusText;
    }
    return data;
  }

  async put(key: string, value: any): Promise<void> {
    const { ok, statusText } = await this.getOrbit().put(`${this.prefix}/${key}`, value);
    if (!ok) {
      throw statusText;
    }
  }

  async list(prefix?: string): Promise<string[]> {
    const p = prefix ? `${this.prefix}/${prefix}` : `${this.prefix}/`;
    const { ok, statusText, data } = await this.getOrbit().list(p);
    if (!ok) {
      throw statusText;
    }
    const keys: string[] = data;
    return keys.map((key) => key.substring(this.prefix.length + 1));
  }

  async delete(key: string): Promise<void> {
    const { ok, statusText } = await this.getOrbit().delete(`${this.prefix}/${key}`);
    if (!ok) {
      throw statusText;
    }
  }

  async afterConnect(ssx: SSXConnected): Promise<ConfigOverrides> {
    await initialized;
    (global as any).keplerModule = kepler;
    this.wallet = ssx.provider.getSigner();
    this.orbitId = kepler.makeOrbitId(
      await this.wallet.getAddress(),
      await this.wallet.getChainId(),
    );
    this.domain = ssx.config.sessionConfig?.domain;
    return {};
  }

  async targetedActions(): Promise<{ [target: string]: string[]; }> {
    const actions = {};
    actions[`${this.orbitId}/kv/${this.prefix}/`] = ['put', 'get', 'list', 'del', 'metadata'];
    return actions;
  }

  async afterSignIn(ssxSession: SSXSession): Promise<void> {
    const keplerHost = this.config.hosts[0];
    const session = await Promise.resolve({
      jwk: JSON.parse(ssxSession.sessionKey),
      orbitId: this.orbitId,
      service: 'kv',
      siwe: ssxSession.siwe,
      signature: ssxSession.signature,
      verificationMethod: new SiweMessage(ssxSession.siwe).uri,
    })
      .then(JSON.stringify)
      .then(kepler.completeSessionSetup)
      .then(JSON.parse);

    return activateSession(session, keplerHost)
      .catch(async ({ status, msg }) => {
        if (status !== 404) {
          throw new Error(`Failed to submit session key delegation to Kepler: ${msg}`);
        }
        const { status: hostStatus, statusText } = await hostOrbit(
          this.wallet,
          keplerHost,
          this.orbitId,
          this.domain,
        );
        if (hostStatus !== 200) {
          throw new Error(`Failed to open new Kepler Orbit: ${statusText}`);
        }
        return activateSession(session, keplerHost);
      })
      .then((authn) => { this.orbit = new OrbitConnection(keplerHost, authn); });
  }
}
